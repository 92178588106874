<template>
  <div>
    <cohort-list-add-new :is-add-new-cohort-sidebar-active.sync="isAddNewCohortSidebarActive" @refetch-data="refetchData" />

    <!-- Filters -->
    <cohort-list-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
      :create-at-start-filter.sync="createAtStartFilter"
      :create-at-end-filter.sync="createAtEndFilter"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2 mb-0">
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>

            <b-button v-if="false" :to="{ name: 'apps-roster-search-cohort' }" class="ml-1" variant="primary">
              Advanced Search
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
              <b-button variant="info" :to="{ name: 'apps-accounting-receivable-cohort-import' }" class="mr-1">
                <span class="text-nowrap">Import Cohort</span>
              </b-button>
              <b-button variant="primary" @click="isAddNewCohortSidebarActive = true">
                <span class="text-nowrap">Create Cohort</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCohortListTable"
        class="position-relative"
        :items.sync="fetchCohorts"
        responsive
        :fields="tableColumns"
        :filter.sync="searchQuery"
        :filterByFormatted="true"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
      >
        <!-- Column: Cohort name -->
        <template #cell(name)="data">
          <b-link
            :to="{ name: 'apps-accounting-receivable-cohort-view', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name }}
          </b-link>
        </template>

        <!-- Column: Session name -->
        <template #cell(session.name)="data">
          <b-link
            v-if="data.item.session && data.item.session.id"
            :to="{ name: 'apps-roster-session-view', params: { id: data.item.session.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.session.name }}
          </b-link>
          <span v-else>Unassigned</span>
        </template>

        <!-- Column: total -->
        <template #cell(total)="data">
          <span class="text-nowrap">{{ money(data.item.total) }}</span>
        </template>

        <!-- Column: balance -->
        <template #cell(totalPaid)="data">
          <span class="text-nowrap">{{ money(data.item.totalPaid) }}</span>
        </template>

        <!-- Column: balance -->
        <template #cell(balance)="data">
          <span class="text-nowrap">{{ money(data.item.balance) }}</span>
        </template>

        <!-- Column: Program Start Date -->
        <template #cell(program.startDate)="data">
          {{ moment.utc(data.item.program.startDate).format('L') }}
        </template>

        <!-- Column: Program End Date -->
        <template #cell(program.endDate)="data">
          {{ moment.utc(data.item.program.endDate).format('L') }}
        </template>

        <!-- Column: Session -->
        <template #cell(createdAt)="data">
          {{ moment.utc(data.item.createdAt).format('L') }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-${resolveCohortStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ title(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-link :to="{ name: 'apps-accounting-receivable-cohort-edit', params: { id: data.item.id } }">
              <feather-icon :id="`cohort-row-${data.item.id}-edit-icon`" icon="EditIcon" class="cursor-pointer" size="16" />
              <b-tooltip title="Edit Cohort" class="cursor-pointer" :target="`cohort-row-${data.item.id}-edit-icon`" />
            </b-link>

            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>

              <b-dropdown-item :to="{ name: 'apps-accounting-receivable-cohort-edit', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="archiveCohort(data.item.id)">
                <feather-icon icon="ArchiveIcon" />
                <span class="align-middle ml-50">Archive</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCohorts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText, title } from '@core/utils/filter';
import CohortListFilters from './CohortListFilters.vue';
import useCohortsList from './useCohortsList';
import receivableStoreModule from '../receivableStoreModule';
import CohortListAddNew from './CohortListAddNew.vue';
import moment from 'moment';

export default {
  components: {
    CohortListFilters,
    CohortListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  data: () => {
    return { isAddSessionOpen: false };
  },
  methods: {
    money(v) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(v);
    },
    startSessionSelect(cohort) {
      this.sessionCohort = cohort;
      this.isAddSessionOpen = true;
    },
    archiveCohort(cohortId) {
      store
        .dispatch('app-accounting-receivable/updateCohort', {
          id: cohortId,
          cohortData: {
            status: 'archived',
          },
        })
        .then((response) => {
          this.refetchData();
        });
    },
  },
  mounted() {
    if (this.$route.query.op === 'create') {
      this.isAddNewCohortSidebarActive = true;
    }
  },
  setup() {
    const COHORT_APP_STORE_MODULE_NAME = 'app-accounting-receivable';

    // Register module
    if (!store.hasModule(COHORT_APP_STORE_MODULE_NAME))
      store.registerModule(COHORT_APP_STORE_MODULE_NAME, receivableStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COHORT_APP_STORE_MODULE_NAME)) store.unregisterModule(COHORT_APP_STORE_MODULE_NAME);
    });

    const isAddNewCohortSidebarActive = ref(false);

    const {
      fetchCohorts,
      tableColumns,
      perPage,
      currentPage,
      totalCohorts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCohortListTable,
      refetchData,

      // UI

      resolveCohortStatusVariant,
      resolveCohortYNVariant,
      // Extra Filters
      statusFilter,
      statusOptions,

      createAtStartFilter,
      createAtEndFilter,
    } = useCohortsList();

    return {
      // Sidebar
      isAddNewCohortSidebarActive,

      fetchCohorts,
      tableColumns,
      perPage,
      currentPage,
      totalCohorts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCohortListTable,
      refetchData,

      // Filter
      avatarText,
      title,

      // UI
      resolveCohortStatusVariant,
      resolveCohortYNVariant,

      // Extra Filters
      statusFilter,
      statusOptions,

      createAtStartFilter,
      createAtEndFilter,

      //
      moment,
    };
  },
};
</script>

<style scoped lang="scss">
.per-page-selector {
  width: 90px;
}
</style>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
